import { catchError, map } from 'rxjs/operators';
//Angular
import { Injectable } from '@angular/core';
//Services
import { HttpService } from '../http.service';
import { RelationService } from '../model.service';
//Models
import { Customer } from '../../models/models.model';
import { Observable } from 'rxjs';

interface ILoanEligibilityResponse {
  message: number;
  offer_code?: string
}

interface ILoanEligibilityError {
  error: string;
}

@Injectable()
export class ProfileService {
  constructor(
    private http: HttpService,
    private relationService: RelationService
  ) {}

  customerApplyToLoad(
    purchaseId?: string,
  ): Observable<ILoanEligibilityResponse> {
    const params = purchaseId ? { purchase_id: purchaseId } : {};
    if (!params.purchase_id) return;
    return this.http
      .get('v1/checkout/check_customer_apply_to_loan', params)
      .pipe(
        map((response: any): ILoanEligibilityResponse => {

          return {
            message: response.message,
            offer_code: response?.offer_code ?? ''
          };
        }),
        catchError((error: { error: ILoanEligibilityError }) => {
          console.error('Error en verificación de préstamo:', error.error); // 'You need to build your own cart'
          throw error;
        })
      );
  }

  show() {
    return this.http.get('my_account/profiles', {}).pipe(
      map(
        (response) => {
          return this.relationService.parse(response, Customer);
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  edit(customer: Customer) {
    return this.http.patch('my_account/profiles', { user: customer });
  }

  updateEmail(email: String) {
    return this.http.patch('my_account/profiles', { user: { email: email } });
  }

  updatePassword(
    current_password: String,
    password: String,
    password_confirmation: String
  ) {
    return this.http.patch('my_account/profiles', {
      user: {
        current_password: current_password,
        password: password,
        password_confirmation: password_confirmation
      }
    });
  }

  validateCard(validationCard: any) {
    return this.http.post('verify_card', validationCard);
  }

  validateCuil(cuit: string) {
    return this.http.patch('my_account/profiles', { user: { cuit } });
  }

  getValidateCard() {
    return this.http.get('verify_card');
  }
}
